/* code to be used together with css navbar_sticky and navbar-fixed to solve the sticky seccond menu */
const $ = require('jquery');
$(document).ready(function() {

    $(window).scroll(function () {
        //if you hard code, then use console
        //.log to determine when you want the
        //nav bar to stick.
        // console.log($(window).scrollTop());
        if ($(window).scrollTop() > 30) {
            $('#navbar_sticky').addClass('navbar-fixed');
        }
        if ($(window).scrollTop() < 31) {
            $('#navbar_sticky').removeClass('navbar-fixed');
        }
    });

    // The following lines ensure that the active li and a within the main menu have the active class
    // alert(location.pathname);
    // funciona ok
    // $('.navbar-nav > li.active, .navbar-nav > li a.active').removeClass('active');
    // $('a[href="' + location.pathname + '"]')
    //     .closest('li').addClass('active').end()
    //     .closest('a').addClass('active');

    $('#menu li.active, #menu a.active').removeClass('active');
    $('#menu a[href="' + location.pathname + '"]')
        .closest('li').addClass('active').end()
        .closest('a').addClass('active');

    // $('a[href="' + location.pathname + '"]').closest('li').addClass('active');
    // $('a[href="' + location.pathname + '"]').closest('a').addClass('active');


});



$('.dropdown').hover(
    function () {
        $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn();
    },
    function () {
        $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut();
    }
);

$('.dropdown-menu').hover(
    function () {
        $(this).stop(true, true);
    },
    function () {
        $(this).stop(true, true).delay(200).fadeOut();
    }
);


// Next line makes that the top menu option becomes clickable
$('.dropdown-toggle').click(function() { var location = $(this).attr('href'); window.location.href = location; return false; });

console.log('Loaded: navbar.js');


// $('li.has-mega-menu').each(function (idx, val) {
//     var set = 6, //Number of links to display in each column
//         buffer = [],
//         dropdown = $('.dropdown-menu', this),
//         children = dropdown.children(),
//         cols = Math.ceil(children.length / set),
//         col_class = 'col-6 col-md-' + (cols >= 5 ? '2' : (cols == 4 ? '3' : (cols == 3 ? '4' : 'x'))),
//         container_class = 'px-0 container container-' + (cols == 2 ? 'sm' : (cols == 3 ? 'md' : (cols == 4 ? 'lg' : (cols >= 5 ? 'xl' : 'x'))));
//
//     for (var i = 0; i < cols; i++) {
//         buffer.push('<div class="' + col_class + '">');
//         children.slice(i * set, (i + 1) * set).each(function () {
//             buffer.push($(this).prop('outerHTML'));
//         });
//         buffer.push('</div>');
//     }
//
//     dropdown.html('<div class="' + container_class + '"><div class="row">' + buffer.join('\n') + '</div></div>');
// });